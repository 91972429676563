export default [
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/pages/Auth/Login.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/forgot-password',
    name: 'auth-forgot-password',
    component: () => import('@/views/pages/Auth/ForgotPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/reset-password',
    name: 'auth-reset-password',
    component: () => import('@/views/pages/Auth/ResetPassword.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
    },
  },
  {
    path: '/keluhan',
    name: 'keluhan-external',
    component: () => import('@/views/pages/Keluhan/External.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: true,
      pageTitle: 'Keluhan',
      breadcrumb: [
        {
          text: 'Tulis Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/Home.vue'),
    meta: {
      pageTitle: 'Home',
      breadcrumb: [
        {
          text: 'Home',
          active: true,
        },
      ],
    },
  },
  {
    path: '/second-page',
    name: 'second-page',
    component: () => import('@/views/SecondPage.vue'),
    meta: {
      pageTitle: 'Second Page',
      breadcrumb: [
        {
          text: 'Second Page',
          active: true,
        },
      ],
    },
  },

  {
    path: '/disposisi/proses-disposisi',
    name: 'proses-disposisi',
    component: () => import('@/views/pages/Disposisi/DaftarDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Proses Disposisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposisi/selesai',
    name: 'disposisi-selesai',
    component: () => import('@/views/pages/Disposisi/DaftarDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Disposisi Selesai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposisi/tambah-disposisi',
    name: 'tambah-disposisi',
    component: () => import('@/views/pages/Disposisi/AddDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Tambah Disposisi',
          active: true,
        },
      ],
    },
  },
  {
    path: '/disposisi/edit-disposisi/*',
    name: 'edit-disposisi',
    component: () => import('@/views/pages/Disposisi/EditDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Edit Disposisi',
          active: true,
        },
      ],
    },
    beforeEnter(to, from, next) {
      if (JSON.parse(localStorage.getItem('userData')).data.data.is_admin === 1) {
        next()
      } else {
        next('/disposisi/proses-disposisi')
      }
    },
  },
  {
    path: '/disposisi/detail-disposisi/*',
    name: 'detail-disposisi',
    component: () => import('@/views/pages/Disposisi/EditDisposisi.vue'),
    meta: {
      pageTitle: 'Disposisi',
      breadcrumb: [
        {
          text: 'Detail Disposisi',
          active: true,
        },
      ],
    },
  },

  {
    path: '/disposisi/tambah-perintah',
    name: 'tambah-perintah',
    component: () => import('@/views/pages/Perintah/AddPerintah.vue'),
    meta: {
      pageTitle: 'Perintah',
      breadcrumb: [
        {
          text: 'Tambah Perintah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perintah/proses',
    name: 'perintah-proses',
    component: () => import('@/views/pages/Perintah/DaftarPerintah.vue'),
    meta: {
      pageTitle: 'Perintah',
      breadcrumb: [
        {
          text: 'Proses Perintah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perintah/selesai',
    name: 'perintah-selesai',
    component: () => import('@/views/pages/Perintah/DaftarPerintah.vue'),
    meta: {
      pageTitle: 'Perintah',
      breadcrumb: [
        {
          text: 'Perintah Selesai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perintah/detail-perintah/*',
    name: 'detail-perintah',
    component: () => import('@/views/pages/Perintah/DetailPerintah.vue'),
    meta: {
      pageTitle: 'Perintah',
      breadcrumb: [
        {
          text: 'Detail Perintah',
          active: true,
        },
      ],
    },
  },
  {
    path: '/perintah/edit-perintah/*',
    name: 'edit-perintah',
    component: () => import('@/views/pages/Perintah/DetailPerintah.vue'),
    meta: {
      pageTitle: 'Perintah',
      breadcrumb: [
        {
          text: 'Edit Perintah',
          active: true,
        },
      ],
    },
    beforeEnter(to, from, next) {
      if (JSON.parse(localStorage.getItem('userData')).data.data.is_admin === 1) {
        next()
      } else {
        next('/perintah/proses')
      }
    },
  },
  {
    path: '/surat-keluar/tulis',
    name: 'surat-keluar-tulis',
    component: () => import('@/views/pages/SuratKeluar/TulisSurat.vue'),
    meta: {
      pageTitle: 'Surat Keluar',
      breadcrumb: [
        {
          text: 'Tulis Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/surat-keluar/proses',
    name: 'surat-keluar-proses',
    component: () => import('@/views/pages/SuratKeluar/DaftarSurat.vue'),
    meta: {
      pageTitle: 'Surat Keluar',
      breadcrumb: [
        {
          text: 'Proses Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/surat-keluar/edit-surat-keluar/*',
    name: 'edit-surat-keluar',
    component: () => import('@/views/pages/SuratKeluar/EditSurat.vue'),
    meta: {
      pageTitle: 'Surat Keluar',
      breadcrumb: [
        {
          text: 'Edit Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/surat-keluar/detail-surat-keluar/*',
    name: 'detail-surat-keluar',
    component: () => import('@/views/pages/SuratKeluar/EditSurat.vue'),
    meta: {
      pageTitle: 'Surat Keluar',
      breadcrumb: [
        {
          text: 'Detsil Surat Keluar',
          active: true,
        },
      ],
    },
  },
  {
    path: '/surat-keluar/selesai',
    name: 'surat-keluar-daftar',
    component: () => import('@/views/pages/SuratKeluar/DaftarSurat.vue'),
    meta: {
      pageTitle: 'Surat Keluar Selesai',
      breadcrumb: [
        {
          text: 'Daftar Surat Keluar Selesai',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-data/pengajuan',
    name: 'permintaan-data-pengajuan',
    component: () => import('@/views/pages/PermintaanData/Pengajuan.vue'),
    meta: {
      pageTitle: 'Permintaan Data',
      breadcrumb: [
        {
          text: 'Pengajuan Permintaan Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-data/daftar',
    name: 'daftar-permintaan-data',
    component: () => import('@/views/pages/PermintaanData/Daftar.vue'),
    meta: {
      pageTitle: 'Permintaan Data',
      breadcrumb: [
        {
          text: 'Daftar Permintaan Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-data/detail/*',
    name: 'detail-permintaan-data',
    component: () => import('@/views/pages/PermintaanData/Edit.vue'),
    meta: {
      pageTitle: 'Permintaan Data',
      breadcrumb: [
        {
          text: 'Detail Permintaan Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-data/edit/*',
    name: 'edit-permintaan-data',
    component: () => import('@/views/pages/PermintaanData/Edit.vue'),
    meta: {
      pageTitle: 'Permintaan Data',
      breadcrumb: [
        {
          text: 'Edit Permintaan Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/permintaan-data/detail/:id',
    name: 'detail-permintaan-data',
    component: () => import('@/views/pages/PermintaanData/Edit.vue'),
    meta: {
      pageTitle: 'Permintaan Data',
      breadcrumb: [
        {
          text: 'Detail Permintaan Data',
          active: true,
        },
      ],
    },
  },
  {
    path: '/pages/account-setting',
    name: 'pages-account-setting',
    component: () => import('@/views/pages/account-setting/AccountSetting.vue'),
    meta: {
      pageTitle: 'Account Settings',
      breadcrumb: [
        {
          text: 'Pages',
        },
        {
          text: 'Account Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/keluhan/tambah',
    name: 'tambah-keluhan',
    component: () => import('@/views/pages/Keluhan/TambahKeluhan.vue'),
    meta: {
      pageTitle: 'Keluhan',
      breadcrumb: [
        {
          text: 'Tambah Keluhan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/keluhan/daftar-internal',
    name: 'daftar-keluhan',
    component: () => import('@/views/pages/Keluhan/DaftarKeluhan.vue'),
    meta: {
      pageTitle: 'Keluhan Internal',
      breadcrumb: [
        {
          text: 'Daftar Keluhan Internal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/keluhan/daftar-eksternal',
    name: 'daftar-keluhan-ex',
    component: () => import('@/views/pages/Keluhan/DaftarKeluhan.vue'),
    meta: {
      pageTitle: 'Keluhan Eksternal',
      breadcrumb: [
        {
          text: 'Daftar Keluhan Eksternal',
          active: true,
        },
      ],
    },
  },
  {
    path: '/keluhan/edit-keluhan/*',
    name: 'edit-keluhan',
    component: () => import('@/views/pages/Keluhan/DetailKeluhan.vue'),
    meta: {
      pageTitle: 'Keluhan',
      breadcrumb: [
        {
          text: 'Edit Keluhan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/keluhan/detail-keluhan/*',
    name: 'detail-keluhan',
    component: () => import('@/views/pages/Keluhan/DetailKeluhan.vue'),
    meta: {
      pageTitle: 'Keluhan',
      breadcrumb: [
        {
          text: 'Detail Keluhan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arsip/tambah-arsip',
    name: 'tambah-arsip',
    component: () => import('@/views/pages/Arsip/TambahArsip.vue'),
    meta: {
      pageTitle: 'Arsip',
      breadcrumb: [
        {
          text: 'Tambah Arsip',
          active: true,
        },
      ],
    },
  },
  {
    path: '/surat-masuk',
    name: 'surat-masuk',
    component: () => import('@/views/pages/Disposisi/DaftarDisposisi.vue'),
  },
  {
    path: '/perintah',
    name: 'perintah',
    component: () => import('@/views/pages/Perintah/DaftarPerintah.vue'),
  },
  {
    path: '/surat-keluar',
    name: 'surat-keluar',
    component: () => import('@/views/pages/SuratKeluar/DaftarSurat.vue'),
  },
  {
    path: '/perjanjian-bisnis',
    name: 'perjanjian-bisnis',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/perjanjian-organisasi',
    name: 'perjanjian-organisasi',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/surat-tanah',
    name: 'surat-tanah',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/surat-kendaraan',
    name: 'surat-kendaraan',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/sk-pengurus',
    name: 'sk-pengurus',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/sk-manajemen',
    name: 'sk-manajemen',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/berita-acara',
    name: 'berita-acara',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/surat-legal',
    name: 'surat-legal',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/sertifikasi',
    name: 'sertifikasi',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/sertifikat-penghargaan',
    name: 'sertifikat-penghargaan',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
  },
  {
    path: '/arsip',
    name: 'arsip',
    component: () => import('@/views/pages/Arsip/DaftarArsip.vue'),
    meta: {
      pageTitle: 'Arsip',
      breadcrumb: [
        {
          text: 'Daftar Arsip',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arsip/edit-arsip/*',
    name: 'edit-arsip',
    component: () => import('@/views/pages/Arsip/DetailArsip.vue'),
    meta: {
      pageTitle: 'Arsip',
      breadcrumb: [
        {
          text: 'Edit Arsip',
          active: true,
        },
      ],
    },
  },
  {
    path: '/arsip/detail-arsip/*',
    name: 'detail-arsip',
    component: () => import('@/views/pages/Arsip/DetailArsip.vue'),
    meta: {
      pageTitle: 'Arsip',
      breadcrumb: [
        {
          text: 'Detail Arsip',
          active: true,
        },
      ],
    },
  },
  {
    path: '/agenda',
    name: 'agenda',
    component: () => import('@/views/pages/Agenda/Calendar.vue'),
    meta: {
      pageTitle: 'Daftar Agenda',
      breadcrumb: [
        {
          text: 'Agenda',
          active: true,
        },
      ],
    },
  },

  {
    path: '/security/tambah-kejadian',
    name: 'tambah-kejadian',
    component: () => import('@/views/pages/Security/SecurityAdd.vue'),
    meta: {
      pageTitle: 'Security',
      breadcrumb: [
        {
          text: 'Tambah Kejadian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/security/edit-kejadian/*',
    name: 'edit-kejadian',
    component: () => import('@/views/pages/Security/SecurityAdd.vue'),
    meta: {
      pageTitle: 'Security',
      breadcrumb: [
        {
          text: 'Edit Kejadian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/security/daftar-kejadian',
    name: 'daftar-kejadian',
    component: () => import('@/views/pages/Security/SecurityList.vue'),
    meta: {
      pageTitle: 'Security',
      breadcrumb: [
        {
          text: 'Daftar Kejadian',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kendaraan/tambah',
    name: 'tambah-kendaraan',
    component: () => import('@/views/pages/Kendaraan/Tambah.vue'),
    meta: {
      pageTitle: 'Kendaraan',
      breadcrumb: [
        {
          text: 'Tambah Log Kendaraan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kendaraan/daftar',
    name: 'daftar-kendaraan',
    component: () => import('@/views/pages/Kendaraan/Daftar.vue'),
    meta: {
      pageTitle: 'Kendaraan',
      breadcrumb: [
        {
          text: 'Daftar Log Kendaraan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kendaraan/detail/*',
    name: 'detail-kendaraan',
    component: () => import('@/views/pages/Kendaraan/Detail.vue'),
    meta: {
      pageTitle: 'Kendaraan',
      breadcrumb: [
        {
          text: 'Detail Log Kendaraan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kendaraan/edit/*',
    name: 'edit-kendaraan',
    component: () => import('@/views/pages/Kendaraan/Detail.vue'),
    meta: {
      pageTitle: 'Kendaraan',
      breadcrumb: [
        {
          text: 'Edit Log Kendaraan',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kejadian-penting/tambah',
    name: 'tambah-kejadian-penting',
    component: () => import('@/views/pages/KejadianPenting/Tambah.vue'),
    meta: {
      pageTitle: 'Kejadian Penting',
      breadcrumb: [
        {
          text: 'Tambah Kejadian Penting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kejadian-penting/daftar',
    name: 'daftar-kejadian-penting',
    component: () => import('@/views/pages/KejadianPenting/Daftar.vue'),
    meta: {
      pageTitle: 'Kejadian Penting',
      breadcrumb: [
        {
          text: 'Daftar Kejadian Penting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kejadian-penting/detail/*',
    name: 'detail-kejadian-penting',
    component: () => import('@/views/pages/KejadianPenting/Detail.vue'),
    meta: {
      pageTitle: 'Kejadian Penting',
      breadcrumb: [
        {
          text: 'Detail Kejadian Penting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/kejadian-penting/edit/*',
    name: 'edit-kejadian-penting',
    component: () => import('@/views/pages/KejadianPenting/Detail.vue'),
    meta: {
      pageTitle: 'Kejadian Penting',
      breadcrumb: [
        {
          text: 'Edit Kejadian Penting',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tanggap-darurat/tambah',
    name: 'tambah-tanggap-darurat',
    component: () => import('@/views/pages/TanggapDarurat/Tambah.vue'),
    meta: {
      pageTitle: 'Tanggap Darurat',
      breadcrumb: [
        {
          text: 'Tambah Tanggap Darurat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tanggap-darurat/daftar',
    name: 'daftar-tanggap-darurat',
    component: () => import('@/views/pages/TanggapDarurat/Daftar.vue'),
    meta: {
      pageTitle: 'Tanggap Darurat',
      breadcrumb: [
        {
          text: 'Daftar Tanggap Darurat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tanggap-darurat/detail/*',
    name: 'detail-tanggap-darurat',
    component: () => import('@/views/pages/TanggapDarurat/Detail.vue'),
    meta: {
      pageTitle: 'Tanggap Darurat',
      breadcrumb: [
        {
          text: 'Detail Tanggap Darurat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/tanggap-darurat/edit/*',
    name: 'edit-tanggap-darurat',
    component: () => import('@/views/pages/TanggapDarurat/Detail.vue'),
    meta: {
      pageTitle: 'Tanggap Darurat',
      breadcrumb: [
        {
          text: 'Edit Tanggap Darurat',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/pages/UserManagement/list.vue'),
    meta: {
      pageTitle: 'Manajemen Akun',
      breadcrumb: [
        {
          text: 'Pengguna',
          active: true,
        },
      ],
    },
  },
  {
    path: '/group',
    name: 'group',
    component: () => import('@/views/pages/UserManagement/Group.vue'),
    meta: {
      pageTitle: 'Manajemen Akun',
      breadcrumb: [
        {
          text: 'Group',
          active: true,
        },
      ],
    },
  },
  {
    path: '/divisi',
    name: 'role',
    component: () => import('@/views/pages/UserManagement/Role.vue'),
    meta: {
      pageTitle: 'Manajemen Akun',
      breadcrumb: [
        {
          text: 'Divisi',
          active: true,
        },
      ],
    },
  },
]
